/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */

import { LoadingSpinner } from './shared/styling/Styles';
import { lazy, Suspense } from 'react';
const UserAppWrapper = lazy(() => import(/* webpackChunkName: "UserApp" */ './UserAppWrapper'));
const AdminAppWrapper = lazy(() => import(/* webpackChunkName: "AdminApp" */ './AdminAppWrapper'));

export default function App(): JSX.Element {
    if (window.location.href.includes(process.env.REACT_APP_URL + 'admin')) {
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <AdminAppWrapper />
            </Suspense>
        );
    }
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <UserAppWrapper />
        </Suspense>
    );
}

/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */
