import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { IErrorBoundaryState } from '../components/errorHandling/StandardErrorBoundary';

interface IErrorBoundaryContext {
    error: IErrorBoundaryState;
    setError: Dispatch<SetStateAction<IErrorBoundaryState>>;
}

const ErrorBoundaryContext = createContext<IErrorBoundaryContext>({
    error: { isError: false, error: undefined },
    setError: () => {
        return;
    },
});
export default ErrorBoundaryContext;

export function ErrorBoundaryProvider({ children }: { children: JSX.Element }): JSX.Element {
    const [error, setError] = useState<IErrorBoundaryState>({ isError: false, error: undefined });
    return <ErrorBoundaryContext.Provider value={{ error, setError }}>{children}</ErrorBoundaryContext.Provider>;
}
