/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */

import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../components/buttons/Button';

export const StyledLink = styled(Link)`
    color: ${(props) => props.theme.text};
    transition: ${(props) => props.theme.transition};
    height: 100%;
`;

export const DateRangeButtonContainer = styled.div``;

export const DateRangeButton = styled(Button)`
    min-width: 125px;
    margin-right: 10px;
    border-width: thin;

    &:focus {
        background-color: ${(props) => props.theme.navButtonPushed};
    }
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
    animation: ${rotate360} 1s linear infinite;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
    border-top: 0.2em solid ${(props) => props.theme.buttonTertiaryBackground};
    border-radius: 50%;
    width: 2.28571429rem;
    height: 2.28571429rem;
    margin: 0 auto;
    margin-top: 10px;
`;

export const TableLoadingSpinner = styled(LoadingSpinner)`
    margin-top: 100px;
`;

export const BackgroundBlurred = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.5;
`;

export const ModalWindowBase = styled.div`
    background-color: ${(props) => props.theme.modalContentBackgroundColor};
    position: relative;
    max-height: 100%;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 6px;
`;

export const DatePickerStyle = styled.div`
    & .react-datepicker__day--selected {
        background-color: ${(props) => props.theme.ColorPrimaryGreen};
    }
    & .react-datepicker__day--selected:hover {
        background-color: ${(props) => props.theme.ColorPrimaryGreen};
    }
`;

/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */
