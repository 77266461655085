// We're using the non-null assertion here for configuration environment variables
// which should always be defined, so we're disabling the lint wraning for this file.
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export interface Configuration {
    auth0: Auth0Configuration;
    apis: Apis;
    appInsightsKey: string;
    webSiteVersion: string;
    healthCheckServiceNames: HealthCheckServiceNames;
}

export interface Auth0Configuration {
    domain: string;
    clientId: string;
    audience: string;
}

export interface Apis {
    auditUrl: string;
    choicesUrl: string;
    dataGroupUrl: string;
    gageUrl: string;
    invitationUrl: string;
    permissionUrl: string;
    referenceDocumentUrl: string;
    tenantUrl: string;
    userUrl: string;
    vendorUrl: string;
    procedureUrl: string;
    subscriptionsUrl: string;
    notificationsUrl: string;
}

export interface HealthCheckServiceNames {
    audit: string | undefined;
    choices: string | undefined;
    dataGroup: string | undefined;
    gage: string | undefined;
    tenant: string | undefined;
    user: string | undefined;
    vendor: string | undefined;
    notification: string | undefined;
}

const config: Configuration = {
    appInsightsKey: process.env.REACT_APP_APP_INSIGHTS_KEY!,
    webSiteVersion: process.env.REACT_APP_VERSION!,
    auth0: {
        domain: process.env.REACT_APP_AUTH0_DOMAIN!,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
    },
    apis: {
        auditUrl: process.env.REACT_APP_AUDIT_API_URL!,
        choicesUrl: process.env.REACT_APP_CHOICES_API_URL!,
        dataGroupUrl: process.env.REACT_APP_DATAGROUP_API_URL!,
        gageUrl: process.env.REACT_APP_GAGE_API_URL!,
        invitationUrl: process.env.REACT_APP_USERS_API_URL!,
        permissionUrl: process.env.REACT_APP_USERS_API_URL!,
        referenceDocumentUrl: process.env.REACT_APP_GAGE_API_URL!,
        tenantUrl: process.env.REACT_APP_TENANT_API_URL!,
        userUrl: process.env.REACT_APP_USERS_API_URL!,
        vendorUrl: process.env.REACT_APP_VENDOR_API_URL!,
        procedureUrl: process.env.REACT_APP_PROCEDURE_API_URL!,
        subscriptionsUrl: process.env.REACT_APP_USERS_API_URL!,
        notificationsUrl: process.env.REACT_APP_NOTIFICATIONS_API_URL!,
    },
    healthCheckServiceNames: {
        audit: process.env.REACT_APP_AUDIT_HC_SERVICE_NAME!,
        choices: process.env.REACT_APP_CHOICES_HC_SERVICE_NAME!,
        dataGroup: process.env.REACT_APP_DATAGROUP_HC_SERVICE_NAME!,
        gage: process.env.REACT_APP_GAGE_HC_SERVICE_NAME!,
        user: process.env.REACT_APP_USERS_HC_SERVICE_NAME!,
        tenant: process.env.REACT_APP_TENANT_HC_SERVICE_NAME!,
        vendor: process.env.REACT_APP_VENDOR_HC_SERVICE_NAME!,
        notification: process.env.REACT_APP_NOTIFICATIONS_HC_SERVICE_NAME!,
    },
};

export const logoutCallbackUrl =
    process.env.NODE_ENV === 'development'
        ? window.location.origin
        : 'https://www.pqsystems.com/quality-solutions/measurement-devices-and-data/PQ-Nexus/';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID!,
        authority: process.env.REACT_APP_AZURE_AD_AUTHORITY!, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: process.env.REACT_APP_URL + 'admin',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const loginRequest = {
    scopes: [process.env.REACT_APP_AZURE_AD_CLIENT_ID! + '/.default'],
};

export default config;

export function shouldRefreshSummaryDocument(apiUrl?: string): boolean {
    return (
        // Only when environment variable set
        process.env.REACT_APP_AUTOMATICALLY_REFRESH_SUMMARY_DOCUMENTS === 'true' &&
        // Only when in development mode
        process.env.NODE_ENV === 'development' &&
        // Only when accessing localhost
        (apiUrl || '').startsWith('https://localhost') &&
        // But not using mock service
        !(apiUrl || '').startsWith('https://localhost:3004')
    );
}
