/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */

import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { createContext, useContext, useEffect } from 'react';
import config from '../lib/utils/configuration';
import { NexusUserInfoContext } from './NexusUserInfoContext';

const startingValue: { logError: (err) => void; logMessage: (err: string) => void } = {
    logError: (err) => {
        console.log(err);
    },
    logMessage: (err: string) => {
        console.log(err);
    },
};
const AppInsightsContext = createContext(startingValue);
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: config.appInsightsKey,
        distributedTracingMode: DistributedTracingModes.W3C,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: ['*.auth0.*'],
    },
});
function AppInsightsProvider({ children }: { children: JSX.Element }): JSX.Element {
    const { nexusUserInfo } = useContext(NexusUserInfoContext);
    useEffect(() => {
        appInsights.loadAppInsights();
        appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    }, []);

    const logMessage = (msg: string) => {
        appInsights.trackTrace({ message: `LOG: tenant:{${nexusUserInfo.tenant}} message:{${msg}}` });
        appInsights.flush();
    };
    const logError = (err) => {
        appInsights.trackException({ exception: err });
        appInsights.flush();
    };

    return <AppInsightsContext.Provider value={{ logMessage, logError }}>{children}</AppInsightsContext.Provider>;
}

export { AppInsightsContext, AppInsightsProvider };

/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */
