import { useState, createContext, useCallback, useEffect, useRef } from 'react';

export interface Notification {
    title: string;
    description: string | undefined;
    severity: Severity;
    autoDelete?: boolean;
    createdAt?: Date;
    isModal?: boolean;
}

export enum Severity {
    error = 'error',
    success = 'success',
    warning = 'warning',
    info = 'info',
}

interface NotificationContext {
    notifications: Notification[];
    addNotification: (notification: Notification, isModal?: boolean) => void;
    successNotification: (title: string, description: string) => void;
    failureNotification: (title: string, description: string) => void;
    deleteNotification: (index: number) => void;
}

function defaultFunction() {
    // Do nothing
}

const startingValue: NotificationContext = {
    notifications: [],
    addNotification: defaultFunction,
    deleteNotification: defaultFunction,
    successNotification: defaultFunction,
    failureNotification: defaultFunction,
};
const NotificationContext = createContext<NotificationContext>(startingValue);
export default NotificationContext;

export function NotificationProvider({ children }: { children: JSX.Element }): JSX.Element {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const timeoutHandles = useRef<ReturnType<typeof setTimeout>[]>([]);
    const addNotification = useCallback((notification: Notification, isModal = false) => {
        const notificationToAdd = {
            ...notification,
            isModal: isModal,
        };

        setNotifications((current) => {
            const index = current.findIndex((x) => x.title === notificationToAdd.title);
            //if there is an exisiting notification clear the timeout and create a new one essentially refreshing it
            //currently retries occur after 5 seconds provided this stays the same and the timeout value remains above 5000 here
            //things should be fine changing the back delay on query retries may make us want to change the timeouts here in the future
            if (index > -1) {
                const timeout = timeoutHandles?.current?.[index];
                clearTimeout(timeout);
                timeoutHandles.current.splice(index, 1);
                if (notificationToAdd.autoDelete !== false) {
                    timeoutHandles.current.push(
                        setTimeout(() => {
                            setNotifications((n) => {
                                const newList = [...n];
                                newList.shift();
                                return newList;
                            });
                        }, 6000),
                    );
                }
                return current;
            } else {
                if (notificationToAdd.autoDelete !== false) {
                    timeoutHandles.current = [
                        ...timeoutHandles.current,
                        setTimeout(() => {
                            setNotifications((n) => {
                                const newList = [...n];
                                newList.shift();
                                return newList;
                            });
                        }, 6000),
                    ];
                }
                return [...current, { ...notificationToAdd, createdAt: new Date() }];
            }
        });
    }, []);
    const successNotification = useCallback(
        (title: string, description: string) => {
            const notification: Notification = {
                title,
                description,
                severity: Severity.success,
                autoDelete: true,
            };
            addNotification(notification);
        },
        [addNotification],
    );
    const failureNotification = useCallback(
        (title: string, description: string) => {
            const notification: Notification = {
                title,
                description,
                severity: Severity.error,
                autoDelete: true,
            };
            addNotification(notification);
        },
        [addNotification],
    );
    const deleteNotification = useCallback((index: number) => {
        setNotifications((n) => {
            const newList = [...n];
            newList.splice(index, 1);
            return newList;
        });
    }, []);

    useEffect(
        () => () => {
            console.log('clearing timeouts');
            timeoutHandles.current.map(clearTimeout);
        },
        [timeoutHandles],
    );

    return (
        <NotificationContext.Provider
            value={{ notifications, addNotification, deleteNotification, successNotification, failureNotification }}
        >
            {children}
        </NotificationContext.Provider>
    );
}
