/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */

import { useState, createContext, useEffect } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';

export interface NexusUserInfo {
    tenant: string;
    nexusUserId: string;
    name: string;
    userRoleId: string;
    permissions: string[];
    isTenantAdmin: boolean;
}
interface Auth0UserInfo {
    Tenant: string;
    NexusUserId: string;
    Name: string;
    UserRoleId: string;
    Permissions: string[];
    IsTenantAdmin: boolean;
}

export const metadataPropertyName = 'https://pqnexus.com/app_metadata';

function parseMetadata(user: User | undefined): { isValid: boolean; metadata: Auth0UserInfo[] } {
    const TenantsProp = 'Tenants';
    const metadata = user && user[metadataPropertyName];
    const isValid =
        metadata && metadata[TenantsProp] && Array.isArray(metadata[TenantsProp]) && metadata[TenantsProp].length > 0;
    return {
        isValid,
        metadata: isValid ? metadata[TenantsProp] : undefined,
    };
}

export function getTenantByName(user: User | undefined, tenant: string): NexusUserInfo {
    const { isValid, metadata } = parseMetadata(user);
    if (!isValid) {
        return { tenant: '', nexusUserId: '', name: '', userRoleId: '', permissions: [], isTenantAdmin: false };
    }
    const index = metadata.findIndex((x) => x.Tenant === tenant);
    if (index < 0) {
        return { tenant: '', nexusUserId: '', name: '', userRoleId: '', permissions: [], isTenantAdmin: false };
    }
    const rawValue = metadata[index];
    // Note that in Auth0, the properties are PascalCase and we need them camelCase
    return {
        tenant: rawValue?.Tenant,
        nexusUserId: rawValue?.NexusUserId,
        name: rawValue?.Name,
        userRoleId: rawValue?.UserRoleId,
        permissions: rawValue?.Permissions,
        isTenantAdmin: rawValue?.UserRoleId === 'TenantAdmin',
    };
}

export function getCurrentTenant(): string {
    const localNexusUser = window.localStorage.getItem('nexusUserInfo');
    if (localNexusUser !== null) {
        const userInfo = JSON.parse(localNexusUser);
        return userInfo?.tenant;
    }
    return startingValue.nexusUserInfo.tenant;
}

export function getAllTenants(user: User | undefined): NexusUserInfo[] {
    const { isValid, metadata } = parseMetadata(user);
    if (!isValid) {
        return [];
    }

    // Note that in Auth0, the properties are PascalCase and we need them camelCase
    return metadata.map((t) => {
        return {
            tenant: t.Tenant,
            nexusUserId: t.NexusUserId,
            name: t.Name,
            userRoleId: t.UserRoleId,
            permissions: t.Permissions,
            isTenantAdmin: t.UserRoleId === 'TenantAdmin',
        };
    });
}

export function getDefaultTenant(user: User | undefined) {
    const { isValid, metadata } = parseMetadata(user);
    if (!isValid) {
        return { tenant: '', nexusUserId: '', name: '', userRoleId: '', permissions: [], isTenantAdmin: false };
    }
    const rawValue = metadata[0];

    // Note that in Auth0, the properties are PascalCase and we need them camelCase
    return {
        tenant: rawValue.Tenant,
        nexusUserId: rawValue.NexusUserId,
        name: rawValue.Name,
        userRoleId: rawValue.UserRoleId,
        permissions: rawValue.Permissions,
        isTenantAdmin: rawValue.UserRoleId === 'TenantAdmin',
    };
}

export function hasMultipleTenants(user: User | undefined): boolean {
    const { isValid, metadata } = parseMetadata(user);
    if (!isValid) {
        return false;
    }
    return metadata.length > 1;
}

const startingValue: { nexusUserInfo: NexusUserInfo; setNexusUserInfo: (t: NexusUserInfo) => void } = {
    nexusUserInfo: { tenant: '', nexusUserId: '', name: '', userRoleId: '', permissions: [], isTenantAdmin: false },
    setNexusUserInfo: () => {
        return;
    },
};
const NexusUserInfoContext = createContext(startingValue);

function NexusUserInfoProvider({
    children,
    auth0Tenant,
}: {
    children: JSX.Element[] | JSX.Element;
    auth0Tenant: NexusUserInfo;
}): JSX.Element {
    const { user } = useAuth0();

    useEffect(() => {
        if (user) {
            const currentTenant = getCurrentTenant();
            const updatedNexusUserInfo = getTenantByName(user, currentTenant);
            setNexusUserInfoStorage(updatedNexusUserInfo);
        }
    }, [user]);

    const nexusUserInfoStorage = (): NexusUserInfo => {
        const localNexusUser = window.localStorage.getItem('nexusUserInfo');
        if (localNexusUser !== null) {
            try {
                return JSON.parse(localNexusUser);
            } catch (e) {
                console.error(e, 'failed parsing nexus user');
            }
        } else {
            window.localStorage.setItem('nexusUserInfo', JSON.stringify(auth0Tenant));
        }
        return auth0Tenant;
    };
    const [nexusUserInfo, setNexusUserInfo] = useState<NexusUserInfo>(nexusUserInfoStorage);

    const setNexusUserInfoStorage = (info: NexusUserInfo) => {
        window.localStorage.setItem('nexusUserInfo', JSON.stringify(info));
        setNexusUserInfo(info);
    };

    return (
        <NexusUserInfoContext.Provider
            value={{ nexusUserInfo: nexusUserInfo, setNexusUserInfo: setNexusUserInfoStorage }}
        >
            {children}
        </NexusUserInfoContext.Provider>
    );
}

export { NexusUserInfoContext, NexusUserInfoProvider };

/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */
