import styled, { css } from 'styled-components';
import color from 'color';
import { forwardRef, SyntheticEvent } from 'react';

export interface ButtonProps {
    /** Primary button style for the button this is the default */
    primary?: boolean;
    /** Secondary button style */
    secondary?: boolean;
    /** Tertiary button style */
    tertiary?: boolean;
    /** Makes button autoFocus after page load */
    autoFocus?: boolean;
    /** Creates the button with a smaller size */
    small?: boolean;
    /** Text or component to put in the button */
    children: string;
    /** On Click function*/
    onClick?: (e: SyntheticEvent) => void;
    /** Handles disabled state for the button */
    disabled?: boolean;
    /** Specifies the type of button: (button, reset, submit) */
    type?: string;
    /** Can add a role to a button we shouldn't really be using this one as it hurts accessiblity */
    role?: string;
    /** Sets Button Id */
    id?: string;
    /** Id for the form the button belongs to */
    form?: string;
}

type buttonTheme = {
    tertiary: { backgroundColor: string; textColor: string; disabled: string; hover: string };
    secondary: { backgroundColor: string; textColor: string; activeBorder: string; disabled: string; hover: string };
    primary: {
        backgroundColor: string;
        textColor: string;
        activeBorder: string;
        disabled: string;
        disabledText: string;
        hover: string;
    };
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props: ButtonProps, ref?): JSX.Element {
    return <StyledButton type="button" ref={ref} {...props}></StyledButton>;
});
export default Button;
export const buttonBaseStyle = css`
    border: 0;
    border-radius: 999px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    &:enabled {
        cursor: pointer;
    }

    ${(props) => (props.theme.button ? addThemedStyles(props, props.theme.button) : '')}
    ${(props) => addSizeStyles(props)}
`;
export const StyledButton = styled.button`
    ${buttonBaseStyle}
`;
const addSizeStyles = (props) => (props.small ? smallStyles() : largeStyles());
export const addThemedStyles = (props: { tertiary: string; secondary: string }, btnTheme: buttonTheme): string =>
    props.tertiary
        ? tertiaryStyles(btnTheme.tertiary)
        : props.secondary
        ? secondaryStyles(btnTheme.secondary)
        : primaryStyles(btnTheme.primary);

const primaryStyles = ({ backgroundColor, textColor, activeBorder, disabled, disabledText, hover }) => `
    background-color: ${backgroundColor};
    color: ${textColor};
    border:  2px solid ${backgroundColor};

    &:hover {
         background-color:${hover};
         color:${disabledText};
    }
    &:active {
        border-color: ${activeBorder};
        background-color: ${color(backgroundColor).darken(0.5).hex()};
        color: ${textColor};
    }
    &:focus {
        border-color: ${activeBorder};
    }
    &:disabled {
        background-color: ${disabled};
        opacity:15%;
        color:${disabledText}
    }
     
`;

const secondaryStyles = ({ backgroundColor, textColor, activeBorder, disabled, hover }) => `
    background-color: ${backgroundColor};
    color: ${textColor};
    border: solid 2px ${textColor};
    &:hover {
        background-color:${hover};
    }
    &:active {
        border-color: ${activeBorder};
        color: ${color(textColor).darken(1).hex()};
        border: solid 2px ${color(textColor).darken(1).hex()};
    }
    &:focus {
        border-color: ${activeBorder};
    }
    &:disabled {
        background-color: ${disabled};
        opacity:15%;
    }
`;

const smallStyles = () => `
    height: 26px;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 0px;
    border: solid 1px ${(props) => props.theme.textColor};
`;
const largeStyles = () => `
  height: 35px;
  min-width: 160px;
`;

const tertiaryStyles = ({ backgroundColor, textColor, disabled, hover }) => `
    background-color: ${backgroundColor};
    color: ${textColor};
    border: solid 2px ${color(backgroundColor).darken(0.128).desaturate(0.5).hex()};
    &:hover {
        background-color: ${hover};
    }
    &:active {
        background-color: ${color(backgroundColor).darken(0.1).hex()};
    }
    &:disabled {
        background-color: ${disabled};
        opacity:15%;
    }
`;

export const StyledNavButton = styled.button`
    background-color: ${(props) => props.theme.selectedTabBackground};
    &:focus {
        background-color: ${(props) => props.theme.navButtonPushed};
        outline: unset;
    }
`;

export const IconOnlyButton = styled.button`
    background-color: transparent;
    transition: ${(props) => props.theme.transition};
    color: ${(props) => props.theme.text};
    border: none;
    width: 40px;
    display: inline-block;
    &:enabled {
        cursor: pointer;
    }
    margin-left: ${(props) => (props.alignRight ? 'auto' : 0)};
`;
