/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */

import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import './config/i18n';
import { NotificationProvider } from './shared/contexts/NotificationContext';
import { AppInsightsProvider } from './shared/contexts/appInsightsContext';
import { StrictMode } from 'react';
import { ErrorBoundaryProvider } from './shared/contexts/ErrorBoundaryContext';
import App from './App';
declare global {
    interface Window {
        Cypress: unknown;
    }
}

ReactDOM.render(
    <StrictMode>
        <BrowserRouter>
            <ErrorBoundaryProvider>
                <NotificationProvider>
                    <AppInsightsProvider>
                        <App />
                    </AppInsightsProvider>
                </NotificationProvider>
            </ErrorBoundaryProvider>
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

/*! @preserve Copyright © 2023 PQ Systems an Advantive Company
 * @license
 * All Rights reserved.
 */
